
import { computed, defineComponent, onMounted, ref, PropType } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import * as Yup from "yup";
import { Exploitation, CoopInfos } from "@/store/modules/ExploitationsModule";
import {
  areObjectsEqual,
  deepCopy,
  notify,
  parseDate,
} from "@/core/helpers/globalJaya";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Multiselect from "@vueform/multiselect";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { Indicator, SelectedIndicator } from "@/store/modules/PrescriberModule";
import { AdditionalIndicator } from "@/store/modules/ExploitationsModule";
import store from "@/store";
import exploitations from "@/views/exploitations/Exploitations.vue";

class FrGouvProvider extends OpenStreetMapProvider {
  constructor() {
    super({
      searchUrl: "https://api-adresse.data.gouv.fr/search",
      reverseUrl: "https://api-adresse.data.gouv.fr/reverse",
    });
  }

  parse({ data }) {
    return data.features.map((item) => ({
      x: item.geometry.coordinates[0],
      y: item.geometry.coordinates[1],
      label: item.properties.label,
    }));
  }
}

export default defineComponent({
  name: "exploitation-content",
  components: {
    Field,
    Form,
    ErrorMessage,
    CardTitleJaya,
    Multiselect,
  },
  emit: ["update-exploitations"],
  props: {
    initialCreation: { type: Boolean, default: false },
    exploitation: { type: Object as PropType<Exploitation>, required: true },
    editable: { type: Boolean, default: true },
    collapseDefault: { type: Boolean, default: false },
  },
  watch: {
    exploitation(val) {
      this.editingExploitation = deepCopy(val);
    },
  },
  setup: function (props, { emit }) {
    interface GeopointObject {
      label: string;
      x?: number;
      y?: number;
    }

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const validateField = (value: Indicator) => {
      let validator: { error: boolean; message: string } = {
        error: false,
        message: "Champs invalide",
      };
      if (!value.label || additonnalInformations.value[value.label] === null)
        return validator;
      if (value.data_type === "int") {
        if (typeof additonnalInformations.value[value.label] !== "number") {
          validator.error = true;
          validator.message = "Doit être un nombre";
        } else if (additonnalInformations.value[value.label] < 0) {
          validator.error = true;
          validator.message = "Doit être positif";
        }
      }
      if (value.data_type === "perc") {
        if (typeof additonnalInformations.value[value.label] !== "number") {
          validator.error = true;
          validator.message = "Doit être un nombre";
        } else if (additonnalInformations.value[value.label] < 0) {
          validator.error = true;
          validator.message = "Doit être positif";
        } else if (additonnalInformations.value[value.label] > 100) {
          validator.error = true;
          validator.message = "Doit être inférieur à 100";
        }
      }
      return validator;
    };
    const additonnalInformations = ref<Record<string, any>>({});

    let exploitationSubTitle = ref<string>(
      props.exploitation && props.exploitation.location_address
        ? props.exploitation.location_address
        : ""
    );

    const exploitationTitle = computed(() => {
      return props.exploitation
        ? props.exploitation.name
        : "Nouvelle exploitation" +
            " - " +
            parseDate(editingExploitationVersion.value.date_created);
    });

    let editingExploitation = ref<Exploitation>({
      date_created: new Date().toISOString(),
      legal_status: -1,
      name: "",
      exploitation_versions: [
        {
          otex: { id: -1 },
          otex_details: "",
          date_created: new Date().toISOString(),
        },
      ],
    });

    let formKey = ref<number>(0);
    let suggestKey = ref<number>(0);
    let showlist = ref<boolean>(false);
    let selectedIndicators = ref<Array<SelectedIndicator>>([]);

    let geoPointsArray = ref<Array<GeopointObject>>([{ label: "" }]);

    let geosearchOptions = {
      provider: new FrGouvProvider(),
    };

    const isOnline = computed(() => store.getters.isOnline);

    const getLegalStatus = computed(() => store.getters.getLegalStatus);

    const getOTEX = computed(() => store.getters.getOTEX);

    const isToUpdate = computed(() => {
      if (props.exploitation) {
        return store.getters.getExploitationsToUpdate.includes(
          props.exploitation.id
        );
      } else {
        return false;
      }
    });

    const getSIQO = computed(() => {
      interface MultiselectOption {
        value: number;
        label: string;
      }

      let siqo = store.getters.getSIQO;
      let resultArray = [] as Array<MultiselectOption>;
      for (const item of siqo) {
        resultArray.push({ value: item.id, label: item.name });
      }
      return resultArray;
    });

    const getBiodiversityProgress = computed(() => {
      interface MultiselectOption {
        value: number;
        label: string;
        name: string;
      }

      let biodiversityProgress = store.getters.getBiodiversityProgress;
      let resultArray = [] as Array<MultiselectOption>;
      for (const item of biodiversityProgress) {
        resultArray.push({
          value: item.id,
          label: item.label,
          name: item.name,
        });
      }
      return resultArray;
    });

    const editingExploitationVersion = computed(() => {
      return editingExploitation.value.exploitation_versions[0];
    });

    const modifications = computed(() => {
      if (props.initialCreation) {
        return true;
      }
      if (props.exploitation) {
        return !areObjectsEqual(editingExploitation.value, props.exploitation);
      }
      return false;
    });

    const isUserFarmer = computed(
      () => store.getters.currentUser.professional_farmer
    );

    const mandatoryFieldToUpdate = computed(
      () => store.getters.getmandatoryFieldToUpdate
    );

    const emptyStringToNull = (value, originalValue) => {
      if (typeof originalValue === "string" && originalValue === "") {
        return null;
      }
      return value;
    };

    const exploitationDetailsNotFarmerValidator = Yup.object().shape({
      name: Yup.string()
        .required("Le ${label} est requis")
        .max(50, "Le ${label} ne doit pas dépasser ${max} caractères")
        .label("nom de l'exploitation"),

      usefull_area: Yup.number()
        .typeError("Doit être un nombre")
        .positive("La SAU doit être positive")
        .label("SAU")
        .transform(emptyStringToNull)
        .nullable(),

      pacage_number: Yup.number()
        .max(999999999, "Le ${label} de doit pas dépasser 9 chiffres")
        .typeError("Doit être un nombre")
        .positive("Le ${label} doit être positif")
        .label("numéro de pacage")
        .transform(emptyStringToNull)
        .nullable(),

      otex_details: Yup.string()
        .max(255, "Le ${label} ne doit pas dépasser ${max} caractères")
        .label("détail Otex"),
    });

    const exploitationDetailsFarmerValidator =
      exploitationDetailsNotFarmerValidator.shape({
        siret: Yup.string()
          .nullable()
          .required("Le ${label} est requis")
          .min(14, "Le ${label} doit être composé de ${min} chiffres")
          .max(14, "Le ${label} ne doit pas dépasser ${max} caractères")
          .label("SIRET"),
        location_address: Yup.string()
          .nullable()
          .label("L'adresse du siège social")
          .required("${label} est requise")
          .test("is-valid-address", "${label} est inconnue", (value) => {
            if (geoPointsArray.value[0].label === "") {
              return true;
            } else if (
              geoPointsArray.value[0].label ===
              "Chargement des adresses en cours..."
            ) {
              return false;
            } else {
              return geoPointsArray.value.some(
                (geoPoint) => geoPoint.label === value
              );
            }
          }),
        legal_status: Yup.number()
          .nullable()
          .label("Le statut juridique")
          .required("Le ${label} est requis")
          .typeError("${label} doit être un nombre")
          .min(0, "${label} est requis"),
        otex: Yup.number()
          .nullable()
          .label("Otex")
          .required("L' ${label} est requis")
          .typeError("${label} doit être un nombre")
          .min(0, "${label} est requis"),
        usefull_area: Yup.number()
          .typeError("Doit être un nombre")
          .positive("La ${label} doit être positive")
          .required("La ${label} est requise")
          .label("SAU"),
        pacage_number: Yup.number()
          .max(999999999, "Le ${label} de doit pas dépasser 9 chiffres")
          .typeError("Doit être un nombre")
          .positive("Le ${label} doit être positif")
          .label("numéro de pacage")
          .required("Le ${label} est requis"),
      });
    const geosearch = (searchText: string) => {
      return new Promise((resolve, reject) => {
        if (searchText.trim().length < 3) {
          // api-adresse needs at least 3 characters
          reject();
        } else {
          geosearchOptions.provider
            .search({ query: searchText })
            .then((results) => {
              resolve(results);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    };

    const manageNoneSiqo = (opt) => {
      if (opt === 6) {
        editingExploitationVersion.value.siqo = [6];
      } else {
        editingExploitationVersion.value.siqo =
          editingExploitationVersion.value.siqo?.filter((elt) => elt !== 6);
      }
    };

    const manageNoneProgess = (opt) => {
      if (opt === 10) {
        editingExploitationVersion.value.biodiversity_progress = [10];
      } else {
        editingExploitationVersion.value.biodiversity_progress =
          editingExploitationVersion.value.biodiversity_progress?.filter(
            (elt) => elt !== 10
          );
      }
    };
    const getGeoPointsArray = (searchText: string) => {
      geosearch(searchText)
        .then((results) => {
          const resultsArray = results as Array<GeopointObject>;
          geoPointsArray.value = !resultsArray.length
            ? [{ label: "Aucun résultat trouvé" }]
            : resultsArray;
        })
        .catch(() => {
          geoPointsArray.value = [{ label: "Aucun résultat trouvé" }];
        });
    };

    const suggestInput = () => {
      if (geoPointsArray.value[0].label === "") {
        geoPointsArray.value = [
          { label: "Chargement des adresses en cours..." },
        ];
      }
      editingExploitation.value.location_point = "";
      setTimeout(() => {
        if (editingExploitation.value.location_address) {
          getGeoPointsArray(editingExploitation.value.location_address);
          showlist.value = true;
          suggestKey.value = suggestKey.value + 1;
        }
      }, 300);
    };

    const newAdress = (geoPoint) => {
      editingExploitation.value.location_address = geoPoint.label;
      editingExploitation.value.location_point =
        "SRID=4326;POINT(" + geoPoint.x + " " + geoPoint.y + ")";
      showlist.value = false;
    };

    const reset = () => {
      Swal.fire({
        text: "Êtes vous certain d'effacer toutes vos modifications sans enregistrer ?",
        icon: "warning",
        confirmButtonText: "Effacer les modifications",
        showCancelButton: true,
        cancelButtonText: "Continuer les modifications",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-warning",
          cancelButton: "btn btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (!props.initialCreation && props.exploitation) {
            editingExploitation.value = deepCopy(
              props.exploitation
            ) as Exploitation;
          } else {
            editingExploitation = ref<Exploitation>({
              id: -1,
              date_created: new Date().toISOString(),
              name: "Nouvelle exploitation",
              exploitation_versions: [
                {
                  id: -1,
                  date_created: new Date().toISOString(),
                  otex_details: "",
                },
              ],
            });
          }
        }
      });
    };

    const deleteExploitation = () => {
      Swal.fire({
        text:
          "Êtes vous certain de supprimer l'exploitation? Cette action est définitive. " +
          "Tous les diagnostics associés à cette exploitation seront également définitivement perdus !",
        icon: "error",
        confirmButtonText: "Annuler",
        showCancelButton: true,
        cancelButtonText: "Supprimer l'exploitation",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light",
          cancelButton: "btn btn-light-danger",
        },
      }).then((result) => {
        if (result.dismiss === "cancel") {
          store.dispatch(Actions.DELETE_EXPLOITATION, {
            id: editingExploitation.value.id,
          });
        }
      });
    };

    const resolveFakeData = () => {
      if (
        editingExploitation.value.legal_status &&
        editingExploitation.value.legal_status < 1
      ) {
        delete editingExploitation.value.legal_status;
      }
      if (
        editingExploitationVersion.value.otex &&
        editingExploitationVersion.value.otex.id &&
        editingExploitationVersion.value.otex.id < 0
      ) {
        delete editingExploitationVersion.value.otex;
      }
    };
    const getFieldsToUpdate = (exploitationVersion) => {
      return selectedIndicators.value.filter((i) => {
        return (
          additonnalInformations.value[
            i.indicator_details?.label ?? "default"
          ] !== null &&
          exploitationVersion.additional_indicators &&
          exploitationVersion.additional_indicators?.findIndex((ai) => {
            return (
              ai.indicator === i.indicator &&
              parseIndicatorFromExploit(ai) !==
                additonnalInformations.value[
                  i.indicator_details?.label ?? "default"
                ]
            );
          }) >= 0
        );
      });
    };
    const getFieldsToCreate = (exploitationVersion) => {
      return selectedIndicators.value.filter((i) => {
        return (
          additonnalInformations.value[
            i.indicator_details?.label ?? "default"
          ] !== null &&
          exploitationVersion.additional_indicators?.findIndex(
            (ai) => ai.indicator === i.indicator
          ) < 0
        );
      });
    };

    const parseValue = (value) => {
      if (value === true) {
        return "Oui";
      }
      if (value === false) {
        return "Non";
      }
      return value.toString();
    };
    const submitChanges = () => {
      if (props.initialCreation || !props.exploitation) {
        // if in initialCreation create new exploitation
        Swal.fire({
          text: "Êtes vous certain d'ajouter cette nouvelle exploitation ?",
          icon: "warning",
          confirmButtonText: "Ajouter",
          showCancelButton: true,
          cancelButtonText: "Annuler",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            resolveFakeData();
            store
              .dispatch(Actions.ADD_EXPLOITATION, {
                exploitation: editingExploitation.value,
                version: editingExploitationVersion.value,
              })
              .then((exploitation) => {
                selectedIndicators.value.map((si) => {
                  if (
                    additonnalInformations.value[
                      si.indicator_details?.label ?? "default"
                    ] !== null
                  ) {
                    store.dispatch(Actions.ADD_INDICATOR_VALUE, {
                      exploitation_version:
                        exploitation.exploitation_versions[0].id,
                      indicator: si.indicator,
                      value: parseValue(
                        additonnalInformations.value[
                          si.indicator_details?.label ?? "default"
                        ]
                      ),
                    });
                  }
                });
                if (route.query && route.query.projectId) {
                  router.push({ name: "homepage" });
                } else {
                  router.push({ name: "new-diagnostic" });
                }
              });
          }
        });
      } else if (
        editingExploitation.value.name !== props.exploitation.name ||
        editingExploitation.value.siret !== props.exploitation.siret ||
        editingExploitation.value.legal_status !==
          props.exploitation.legal_status ||
        editingExploitation.value.location_address !==
          props.exploitation.location_address ||
        editingExploitation.value.location_point !==
          props.exploitation.location_point
      ) {
        Swal.fire({
          text: "Êtes-vous certain de mettre à jour votre exploitation ?",
          icon: "warning",
          confirmButtonText: "Mettre à jour",
          showCancelButton: true,
          cancelButtonText: "Annuler",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            resolveFakeData();
            getFieldsToUpdate(editingExploitationVersion.value).map((f) => {
              const indicator =
                editingExploitationVersion.value.additional_indicators?.find(
                  (i) => i.indicator === f.indicator
                );
              store.dispatch(Actions.UPDATE_INDICATOR_VALUE, {
                id: indicator?.id,
                value: parseValue(
                  additonnalInformations.value[
                    f.indicator_details?.label ?? "default"
                  ]
                ),
              });
            });
            getFieldsToCreate(editingExploitationVersion.value).map((f) => {
              store.dispatch(Actions.ADD_INDICATOR_VALUE, {
                exploitation_version: editingExploitationVersion.value.id,
                indicator: f.indicator,
                value: parseValue(
                  additonnalInformations.value[
                    f.indicator_details?.label ?? "default"
                  ]
                ),
              });
            });
            store.dispatch(Actions.UPDATE_EXPLOITATION, {
              exploitation: editingExploitation.value,
              version: editingExploitationVersion.value,
            });
            emit("update-exploitations");
            router.push({ name: "new-diagnostic" });
          }
        });
      }
      // else ask what user wants
      else {
        Swal.fire({
          text: isToUpdate.value
            ? "Choisissez 'Correction' si les indicateurs que vous avez renseignés correspondent à l'état de votre exploitation lors de la réalisation des diagnostics." +
              "Choisissez 'Évolution des caractéristiques' si vous voulez apporter une modification suite à une évolution réelle des caractéristiques de votre exploitation depuis la réalisation de votre dernier diagnostic. La réalisation d'un nouveau diagnostic vous sera alors proposée.\""
            : "Choisissez 'Correction' si vous voulez apporter une modification suite à une erreur.\n" +
              "Choisissez 'Évolution des caractéristiques' si vous voulez apporter une modification suite à une évolution réelle des caractéristiques de votre exploitation depuis la réalisation de votre dernier diagnostic. La réalisation d'un nouveau diagnostic vous sera alors proposée.",
          icon: "warning",
          confirmButtonText: "Évolution des caractéristiques",
          showCancelButton: true,
          cancelButtonText: "Correction",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            if (props.exploitation && editingExploitationVersion.value) {
              resolveFakeData();
              store
                .dispatch(Actions.ADD_EXPLOITATION_VERSION, {
                  exploitation: props.exploitation.id,
                  date_created: new Date().toISOString(),
                  biodiversity_progress:
                    editingExploitationVersion.value.biodiversity_progress,
                  biodiversity_performance:
                    editingExploitationVersion.value.biodiversity_performance,
                  usefull_area: editingExploitationVersion.value.usefull_area,
                  siqo: editingExploitationVersion.value.siqo,
                  pacage_number: editingExploitationVersion.value.pacage_number,
                  otex: editingExploitationVersion.value.otex,
                  otex_details: editingExploitationVersion.value.otex_details,
                })
                .then((response) => {
                  notify({
                    text: "Nouvelle version d'exploitation ajoutée",
                    color: "success",
                    duration: 3000,
                  });
                  selectedIndicators.value.map((si) => {
                    if (
                      additonnalInformations.value[
                        si.indicator_details?.label ?? "default"
                      ] !== null
                    ) {
                      store.dispatch(Actions.ADD_INDICATOR_VALUE, {
                        exploitation_version: response.id,
                        indicator: si.indicator,
                        value: parseValue(
                          additonnalInformations.value[
                            si.indicator_details?.label ?? "default"
                          ]
                        ),
                      });
                    }
                  });
                  emit("update-exploitations");
                  router.push({ name: "new-diagnostic" });
                });
            } else {
              notify({
                text: "Impossible de trouver l'exploitation, veuillez essayer ulterieurement",
                color: "error",
                duration: 3000,
              });
            }
          } else if (result.dismiss === "cancel") {
            //Attention il faut toujours tout crée si post ci dessus
            getFieldsToUpdate(editingExploitationVersion.value).map((f) => {
              const indicator =
                editingExploitationVersion.value.additional_indicators?.find(
                  (i) => i.indicator === f.indicator
                );
              store.dispatch(Actions.UPDATE_INDICATOR_VALUE, {
                id: indicator?.id,
                value: parseValue(
                  additonnalInformations.value[
                    f.indicator_details?.label ?? "default"
                  ]
                ),
              });
            });
            getFieldsToCreate(editingExploitationVersion.value).map((f) => {
              store.dispatch(Actions.ADD_INDICATOR_VALUE, {
                exploitation_version: editingExploitationVersion.value.id,
                indicator: f.indicator,
                value: parseValue(
                  additonnalInformations.value[
                    f.indicator_details?.label ?? "default"
                  ]
                ),
              });
            });
            resolveFakeData();
            store.dispatch(Actions.UPDATE_EXPLOITATION, {
              exploitation: editingExploitation.value,
              version: editingExploitationVersion.value,
            });
            notify({
              text: "Exploitation mise à jour",
              color: "success",
              duration: 3000,
            });
            emit("update-exploitations");
            router.push({ name: "diagnostic-list" });
          }
        });
      }

      formKey.value = formKey.value + 1;
    };
    const moreRequired = computed(() => {
      if (!isUserFarmer.value && !mandatoryFieldToUpdate.value) {
        return;
      }
      return (
        editingExploitationVersion.value.siqo &&
        editingExploitationVersion.value.siqo.length &&
        editingExploitationVersion.value.biodiversity_progress &&
        editingExploitationVersion.value.biodiversity_progress.length
      );
    });
    const checkOtherOtex = (otexId) => {
      if (
        otexId >= 0 &&
        getOTEX.value.some((e) => {
          return e.name === "Autres - Préciser";
        })
      ) {
        return (
          getOTEX.value.find((e) => {
            return e.name === "Autres - Préciser";
          }).id === otexId
        );
      }
      return false;
    };
    const mergeIndicators = (indicators) => {
      const merged = {};

      indicators.forEach((item) => {
        if (merged[item.indicator]) {
          // If item is already there, set `required` to true if either is true
          merged[item.indicator].required =
            merged[item.indicator].required || item.required;
        } else {
          // Otherwise, add the item to the merged object
          merged[item.indicator] = { ...item };
        }
      });

      // Convert the merged object back to an array
      return Object.values(merged) as Array<SelectedIndicator>;
    };

    onMounted(async () => {
      //set base values of exploitation
      if (props.exploitation) {
        editingExploitation.value = deepCopy(
          props.exploitation
        ) as Exploitation;
        formKey.value = formKey.value + 1;
      } else {
        store.dispatch(Actions.FETCH_EXPLOITATIONS);
        store
          .dispatch(Actions.GET_COOP_INFOS)
          .then((coop_infos: CoopInfos[]) => {
            if (coop_infos.length > 0) {
              editingExploitation.value.siret = coop_infos[0].siret;
            }
          });
      }
      store.dispatch(Actions.FETCH_USER_PROJECTS).then(() => {
        const userProjects = store.getters.getUserProjects;
        const rawIndicators = userProjects.flatMap((p) => {
          return p.additional_indicators;
        });
        selectedIndicators.value = mergeIndicators(rawIndicators);
        for (let indicator of selectedIndicators.value) {
          additonnalInformations.value[
            indicator.indicator_details?.label
              ? indicator.indicator_details?.label
              : "default"
          ] = parseIndicatorFromExploit(
            editingExploitationVersion.value.additional_indicators?.find(
              (ai) => ai.indicator === indicator.indicator
            )
          );
        }
      });

      //update form HTML
      formKey.value = formKey.value + 1;
    });
    const parseIndicatorFromExploit = (
      indicator: AdditionalIndicator | undefined
    ) => {
      if (indicator === undefined) {
        return null;
      }
      if (indicator.indicator_details?.data_type === "bool") {
        return indicator.value === "Oui";
      }
      if (indicator.indicator_details?.data_type === "char") {
        return indicator.value;
      }
      return Number(indicator.value);
    };
    const checkAdditionalIndicators = computed(() => {
      return (
        selectedIndicators.value.filter(
          (i) =>
            (additonnalInformations.value[
              i.indicator_details?.label ? i.indicator_details?.label : ""
            ] === null &&
              i.required) ||
            validateField(i.indicator_details as Indicator).error
        ).length > 0 && isOnline.value
      );
    });
    return {
      exploitationTitle,
      exploitationSubTitle,
      getLegalStatus,
      getOTEX,
      getSIQO,
      getBiodiversityProgress,
      modifications,
      editingExploitation,
      editingExploitationVersion,
      isUserFarmer,
      mandatoryFieldToUpdate,
      exploitationDetailsNotFarmerValidator,
      exploitationDetailsFarmerValidator,
      formKey,
      suggestKey,
      showlist,
      isOnline,
      geoPointsArray,
      getGeoPointsArray,
      suggestInput,
      newAdress,
      reset,
      deleteExploitation,
      submitChanges,
      parseDate: parseDate,
      checkOtherOtex,
      moreRequired,
      manageNoneSiqo,
      manageNoneProgess,
      selectedIndicators,
      additonnalInformations,
      checkAdditionalIndicators,
      validateField,
      isToUpdate,
    };
  },
});
